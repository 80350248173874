$('.acf-fields').on('input', function() {
  if($('body').hasClass('post-type-archive-big_win')) {
    let casino = $('#acf-field_lionbonuses_big_win_casino').select2('data');
    let game = $('#acf-field_lionbonuses_big_win_game').select2('data');
    let nickname = $('#acf-field_lionbonuses_big_win_nickname').val();
    let win = $('#acf-field_lionbonuses_big_win_win_amount').val();
    let bet = $('#acf-field_lionbonuses_big_win_bet_amount').val();
    let currency = $('#acf-field_lionbonuses_big_win_currency').val();

    $('.acf-field-lionbonuses-big-win-bet-amount .acf-input-append').html(currency);

    if(typeof casino[0] !== 'undefined' && typeof game[0] !== 'undefined') {
      casino = casino[0].text;
      game = game[0].text;

      let postTitle = game + ' - ' + casino + ' (' + win + ' ' + currency + ' / ' + bet + ' bet)' + ' | ' + nickname;

      $('input#acf-_post_title').val(postTitle).trigger('change');
    }
  }

  if($('body').hasClass('single-product')) {
    let postTitle = "Order";

    $('input#acf-_post_title').val(postTitle).trigger('change');
  }
})

